(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:DuplicatedPerson
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('DuplicatedPerson', DuplicatedPerson);

  function DuplicatedPerson($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/duplicatedPersons?name=:name&surname=:surname&bornDate=:bornDate', {
      name: '@_name',
      surname: '@_surname',
      bornDate: '@_bornDate'
    });
  }
}());
